.sidebar {
  .custom_html-2 {
    box-shadow: none!important;
    background: none!important;
  }
  .widget {
    text-align: center;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    background: white;
    border-radius:$border-radius;
    margin-bottom: 2rem;
    &:last-child {
      border-bottom: 0px;
    }
    h3,
    .h3 {
      background: $brand-primary;
      border-top-left-radius:$border-radius;
      border-top-right-radius:$border-radius;
      color: #fff;
      padding: 15px;
    }
    .primary-btn {
      @include media-breakpoint-down(lg) {
        font-size: 13px;
        line-height: 1.5em;
      }
    }
    .gform_wrapper input,
    .gform_wrapper textarea,
    .gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield select {
      background: #efefef;
      height: 30px;
      font-size: 13px !important;
      color: #888;
      border: solid 1px #ccc;
    }
    .gform_wrapper input[type="submit"] {
      background: $brand-primary !important;
      background: #00aeef;
      color: #fff;
      height: auto !important;
      border: 0px !important;
    }
    .boxed-header {
      padding: 15px;
      background: $brand-secondary;
      color: #fff !important;
    }
    li.gfield {
      margin-top: 5px;
      ::placeholder {
        color: #888;
      }
      input {
        border: solid 1px #ccc !important;
      }
    }
    li#field_2_2,
    li#field_2_1 {
      margin-top: 0px;
    }
    .menu {
      padding-left: 0px;
    }
    .textwidget {
      padding: 15px;
    }
  }
}
.contact .widget.gform_widget-2 {
  display: none !important;
}
.sidebar {
  margin-bottom: 0px;
  .menu {
    margin-bottom: 0;
  }
  .menu li {
    list-style: none;
    border-bottom: solid 1px #efefef;
    font-size: 14px;
    text-transform: uppercase;
    font-family: $paragraph-font;
    padding: 0.75rem 0;
    &:last-child {
      border-bottom: 0;
    }
  }
  // Search bar
  .widget_search {
    background: transparent;
    box-shadow:none;
    .search-form {
      margin: 0;
      label {
        padding: 0;
      }
    }
  }
}
