.hero-style-1 {
	.hero-main-col {
		.hero-headline {
			font-size:24px;
			text-shadow: 1px 1px 0 #303030;
			font-weight:700;
			font-family:$heading-font;
			@include media-breakpoint-up(lg) {
				font-size:55px;
			}
		}

		.hero-subline {
			font-size:18px;
			text-shadow: 1px 1px 0 #303030;
			font-family:$heading-font;
			@include media-breakpoint-up(lg) {
				font-size:36px;
			}
		}

		.hero-button {
			margin-top:45px;
		}
	}
}

.rpb-cards .card {
	position: relative;
	&:after {
		content:'';
		position: absolute;
		top: 7px;
		right: 7px;
		background: url('../../images/white-icon.png');
		position: absolute;
		width: 40px;
		height: 40px;
		background-position: center;
		background-size: contain;
		opacity: 0.85;
	}
}

.top-cards {
  margin-top: -110px;
}

.bottom-cards {
	 margin-bottom: -110px;
}

.template-homepage {
	.rpb-customer-reviews {
		background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(244,244,244,1) 100%);
	}
	.inner-page-cards {
		display: none;
	}
	.inner-page-reviews {
		display: none!important;
	}
}
