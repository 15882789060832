@import "common/variables";
@import "common/branding";

/** Import everything from autoload */
@import "./autoload/_bootstrap.scss";

/**
 * Import npm dependencies
 *
 * Prefix your imports with `~` to grab from node_modules/
 * @see https://github.com/webpack-contrib/sass-loader#imports
 */
// @import "~some-node-module";

@import '~aos/dist/aos.css';
@import '~@splidejs/splide/dist/css/splide.min.css';
@import '~lightgallery/css/lightgallery-bundle.min.css';

/** Import theme styles */

@import "common/grid";
@import "common/typography";
@import "common/color-classes";
@import "common/animations";
@import "common/global";
@import "common/seo";
@import "components/buttoncore";
@import "components/buttonpresets";
@import "components/comments";
@import "components/forms";
@import "components/wp-classes";
@import "components/splider";

// Navigation
@import "layouts/header/global";
@import "layouts/header/default";
@import "layouts/header/centered";
@import "layouts/header/offcanvas";
@import "layouts/header/mega-menu-extension";

////////////////////////////
// Notices & Popups
////////////////////////////
@import "components/notices";
@import "components/popups";

////////////////////////////
// Range Page Builder
////////////////////////////

@import "range-page-builder/hero";
@import "range-page-builder/simple-content-with-image";
@import "range-page-builder/timeline";
@import "range-page-builder/column-content";
@import "range-page-builder/bootstrap-cards";
@import "range-page-builder/accordion";
@import "range-page-builder/image-slider";
@import "range-page-builder/photo-gallery";
//@import "range-page-builder/internal-page";
@import "range-page-builder/customer-reviews";
@import "range-page-builder/team";
@import "range-page-builder/blog-feed";

////////////////////////////
// Core Layouts
////////////////////////////

@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/search";
@import "layouts/page-header";
@import "layouts/posts";
@import "layouts/tinymce";
@import "layouts/homepage";
@import "layouts/contact-page";
@import "layouts/coupons";
@import "layouts/daily-deals";
@import "layouts/zip-code-search";
@import "layouts/service-area-search";

////////////////////////////
// Location Styles
////////////////////////////

@import "locations/location-map";
@import "locations/single-location";

////////////////////////////
// WooCommerce
////////////////////////////

@import "woocommerce/global";
@import "woocommerce/shop-page";
@import "woocommerce/single-product";
@import "woocommerce/cart";
@import "woocommerce/checkout";


////////////////////////////
// Cannabis Specific Styles
////////////////////////////
@import "cannabis/menu-page";
@import "layouts/agegate";