//default banner
.page-header.default-banner {
  overflow: hidden;
  position: relative;
  h1, .h1, h2, .h2 {
    position: relative;
    z-index: 2;
    font-family: $heading-font;
    text-align: center;
    padding-top:25vw;
    padding-bottom:15px;
    &:before {
      display: none;
    }
    @include media-breakpoint-up(lg) {
      text-align:left;
      padding-top:12vw;
      margin-bottom:15px;
    }
  }
}
.page-title {
  h1, .h1, h2, .h2 {
    margin-bottom: 0px!important;
    position: relative;
    z-index: 2;
    font-family: $heading-font;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    &:before {
      display: none!important;
    }
  }
}
//center-banner
.negative-widget {
  @include media-breakpoint-up(lg) {
    margin-top: -108px;
  }
}
.page-header.center-banner {
  height: 33vh;
  font-size: 24px;
  color: #333;
  h1, .h1 {
    font-size: 1.5em;
    color: $brand-secondary;
    &:after {
      content: '';
      width: 200px;
      height: 2px;
      background: $accent;
      display: block;
      margin: 20px auto 0px;
    }
    @include media-breakpoint-up(md) {
      font-size: 2.5em;
    }
  }
}
.flex-nav {
  @include media-breakpoint-up(xl) {
    display: flex;
    justify-content: flex-end;
  }
}
.content-page-title {
  @include media-breakpoint-down(sm) {
    font-size: 18px!important;
  }
}

.ecommerce-header {
  padding-top:6vw;
  padding-bottom:15px;
}
