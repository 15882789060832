/** Search form */
.search-form {
  @extend .row;
}

.search-form label {
  @extend .mb-3;

  font-weight: normal;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

/* Gravity Forms Styling */

.gform_legacy_markup_wrapper textarea.large {
  @include media-breakpoint-up(md) {
    height:150px!important;
  }
}

.gform_wrapper {
  input, textarea {
    padding: 12px 10px!important;
    border-radius: 8px!important;
    border:2px solid #e2e2e2;
  }
  textarea.medium {
    background: #fff!important;
    border:2px solid #e2e2e2!important;
  }
 .gfield input.large, .gform_wrapper.gravity-theme .gfield select.large, textarea {
     padding: 12px 10px!important;
     border-radius: 8px!important;
     border:2px solid #e2e2e2!important;
     background: #fff!important;
   }
   .gform_button.button {
     background: lighten(green, 10%);
     border-color: lighten(green, 10%);
     color: #fff;
     text-transform: uppercase;
     font-weight: bold;
     padding: 12px 16px!important;
   }
}
