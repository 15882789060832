// Theme Colors/Branding
$brand-primary: #cf1b15;
$brand-primary-rgb: 20,89,186;
$brand-primary-offset: #1459BA;
$brand-secondary: #2aabe5;
$dark: #3E4147;
$light: #f5f5f5;
$medium: #dee2e6;
$inactive: #8c8c8c;
$gray: #EBEBEB;
$white: #fff;
$accent: #58bf3c;
$accent-2: #1459BA;
$body-font: #222;
$error: #d81e00;
